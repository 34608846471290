/*
	COLORS
*/
// Blacks & dark grays
$onyx: #1a1a1a;
$thunder: #4d4d4d;
$gray: #808080;
$aluminum: #999999;
$blackBackground: #535353;
$bodyBackground:#DCDCDC;
$headerBackground: #1F2937;
$linkColor: #5ABDB7;

// Soft grays
$iron: #cccccc;
$mercury: #e6e6e6;
$smoke: #f6f6f6;
// Blue grays
$loblolly: #bccbd2;
$solitude: #e8edef;
// Colors
$blue: #9bc2c7;
$green: #86baae;
// Light colors
$lightBlue: #d1e3e6;
$lightGreen: #c3e0da;
// White
$white: #ffffff;
$primary:#ffc466;
//message colors
$error: #ff6669;
$success: #b5ed93;

/*
	RESPONSIVE BREAKPOINTS
*/
$smallmobilemaxwidth: 361px; //Iphone SE portrait / Galaxy S5
$largemobilemaxwidth: 475px;
$xsMaxWidth: 575px;
$smMaxWidth: 767px;
$mdMaxWidth: 991px;
$lgMaxWidth: 1199px;
$xlMaxWidth: 1439px;
$xlMaxWidth: 1439px;
$xxlMaxWidth:1919px;
$fourKMaxWidth: 2559px;
$headerHeight: 96px;

/*
	FONTS
*/
$titleFont: 'Roboto', Helvetica, Arial, sans-serif;;
$titleMenuFont:'Roboto', Helvetica, Arial, sans-serif;;
$infoFont: 'Roboto', Helvetica, Arial, sans-serif;
$spinkit-size: 48px;
$spinkit-spinner-color:#333333;
/*
	LAYERS
*/
//layer 0 is no z-index
$layer10: 100;
$layer15: 150;
$layer20: 200;
$layer25: 250;
$layer30: 300;
$layer40: 400;
$layer50: 500;
$layer60: 600;

/*
	MARGINS & PADDINGS
*/
$headingBorderRadius: 5px;

/*
	TRANSITIONS FUNCTIONS
*/
$ease: cubic-bezier(0.25, 0.46, 0.45, 0.94);
