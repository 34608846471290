.chat {
}

.chat__button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    background-color: white;
    color: $aluminum;
    border: 5px outset $aluminum;
    position: fixed;
    z-index: 15;
    right: 15px;
    bottom: 15px;
}

.chat__button i {
    font-size: 1.5rem;
}

.chat__head {
    // position: relative;
    margin-top: 5px;
}

.chat__title {
    position: relative;
    color: var(--grey-dark);
    padding-left: 15px;
    margin: 0;
    font-size: 1rem;
    line-height: 25px;
}

.chat__close {
    cursor: pointer;
    position: absolute;
    top: -2px;
    right: 15px;
    display: block;
    color: var(--grey-dark);
    font-size: 50px;
    font-weight: 400;
    z-index: 2;
}

.chat__close::before {
    content: '\D7';
}

.chat__conversation {
    position: relative;
    background: $headerBackground;
    z-index: 0;
}

.conversation {
    overflow-x: hidden;
    padding: 0 10px;
    // margin-bottom: 5px;
    height: 100%;
}

.conversation::after {
    content: '';
    display: table;
    clear: both;
}

.conversation__container {
    height: calc(100% - 95px);
    overflow-y: scroll;
    @media (max-width: $mdMaxWidth) {
        height: calc(100vh - 95px);
    }
}

.message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 8px;
    position: relative;
    margin: 8px 10px;
    max-width: 85%;
    word-wrap: break-word;
    z-index: 1;
}

.message:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
}

.message__metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
}

.metadata__time {
    color: rgba(0, 0, 0, 0.45);
    font-size: 11px;
    display: inline-block;
}

.message--received {
    background: #fff;
    border-radius: 0px 5px 5px 5px;
    float: left;
    margin-bottom: 0 !important;
}

.message--received .message__metadata {
    padding: 0 0 0 16px;
}

.message--received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
    top: 0;
    left: -10px;
}

.message--sent {
    background: $primary;
    border-radius: 5px 0px 5px 5px;
    float: right;
    margin-bottom: 0 !important;
}

.message--sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent $primary;
    top: 0;
    right: -10px;
}
.list-group-item.active{
    background-color: $primary !important;
    border-color: $primary !important;
    color: $onyx !important;
}
.conversation__action {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 50px;
    width: calc(100% - 48px);
    z-index: 2;
}

.action__message {
    border: 0;
    font-size: 1rem;
    margin: 0;
    outline: none;
    width: 100%;
    // width: calc(100% - 48px);
    height: 48px;
    margin-right: 10px;
    border-radius: 5px;
    line-height: 24px;
    padding: 10px 10px;
    box-sizing: border-box;
}

.action__send,
.action__attachment {
    background: $primary;
    margin-top: 2px;
    border-radius: 5px;
    color: $onyx;
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.cardDetail,
.cardDetail--confirm,
.cardDetail--chat,
.cardDetail--info,
.cardDetail--mini {
    position: fixed;
    margin: 0;
    top: 0;
    background-color: #fff;
    width: 100vw;
    // height: 100vh; useless
    box-shadow: 0px 5px 10px hsla(0, 0%, 0%, 0.2);
    overflow: hidden;
    border-radius: 0;
    animation: zoomCard 0.2s ease-out;
}

.cardDetail {
    overflow: unset;
}

.cardDetail__headImg {
    overflow: hidden;
}

.cardDetail--confirm {
    height: 140px;
    top: unset;
    bottom: 0;
    animation: showConfirm 0.2s ease-out;
    border-top: 2px solid $aluminum;
    box-sizing: border-box;
}

.cardDetail--chat {
    height: 100vh;
    top: 0;
    left: 0;
    animation: zoomCard 0.2s ease-out;
    border-top: 2px solid $aluminum;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 35px 1fr;
}

@media (min-width: 700px) {
    .cardDetail,
    .cardDetail--info,
    .cardDetail--mini {
        width: 320px;
        height: 580px;
        top: calc(50% - 290px);
        left: calc(50% - 160px);
    }

    .cardDetail--mini {
        height: 280px;
        top: calc(50% - 140px);
        animation: zoomCard--mini 0.2s ease-out;
    }

    .cardDetail--chat {
        width: 320px;
        height: 568px;
        top: unset;
        left: unset;
        right: 15px;
        bottom: 15px;
    }

    .conversation__container {
        height: calc(568px - 95px);

        
    }

    .cardDetail--confirm {
        max-width: 500px;
        left: calc(50% - 250px);
    }
}

.message__badge {
    background: #686161;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em;
    position: absolute;
    top: -5px;
    right: -8px;
}
.panel-block {
    .message__badge {
        position: relative;
        right: unset;
    }
}


.video-react .video-react-big-play-button {
    top: 40% !important;
    left: 32% !important;
}


.video-react {
    padding-top: 80% !important;
}
