.go-back {
    cursor: pointer;
    display: inline-block;

    &:hover {
        color: $onyx;
    }

    i {
        display: inline-block;
        font-size: 40px;
        vertical-align: middle;
        @media (max-width: $xsMaxWidth) {
            font-size:14px
        }
    }

    .label {
        color: white;
        display: inline-block;
        font-size: 15px;
        margin-left: 10px;
        text-transform: uppercase;
        vertical-align: middle;

        &:hover {
            color: $thunder;
        }
    }
}
