.cart-line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item-list {
    // display: grid;
    // grid-gap: 10px;
    padding: 5px;
}
.line-item {
    .spheres-indicator {
        .new-indicator {
            background: #cf4d3f;
            border-radius: 2px;
            color: #ffffff;
            font-size: 12px;
            font-weight: bold;
            padding: 1px 4px 0;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-family: $titleFont;
            &--black {
                background-color: #4d4d4d;
            }
        }
    }
    .cartline-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        @media (max-width: $smMaxWidth) {
            margin: 8px 0;
        }
    }
    padding: 5px 0;
    .img {
        margin-right: 10px;
        position: relative;
        .technology-indicator {
            background: whitesmoke;
            border-radius: 2px;
            box-shadow: rgba($onyx, 0.3) 1px 1px 3px 1px;
            color: $white;
            display: flex;
            font-size: 11px;
            font-weight: bold;
            height: 15px;
            justify-content: center;
            left: -5px;
            padding: 2px;
            position: absolute;
            text-transform: uppercase;
            top: 40px;
            width: 50px;
            z-index: 1;
            &.plus {
                &:after {
                    color: black;
                    content: '+';
                    font-size: 14px;
                    font-weight: 200;
                    line-height: 14px;
                    margin-left: 2px;
                }
            }
            > img {
                width: 80%;
            }
            &.hybriq {
                background-color: white;
                width: 60px;
                align-items: center;
            }
        }
    }
    display: flex;
    // display: grid;
    // grid-gap: 10px;
    // grid-template-columns: 80px 1fr 50px;
    .justified {
        align-items: center;
        display: flex;
        justify-content: space-around;
    }
    .title {
        // font-size: 14px;
        font-weight: 600;
        flex-grow: 1;
    }
    .subtitle {
        color: gray;
        font-size: 11px;
        font-weight: 500;
    }

    .content {
        flex-grow: 1;

        .props {
            display: flex;
            justify-content: space-between;
        }
        .description-and-input {
            display: flex;
            flex-direction: column;
            height: 100%;
            max-width: 100%;
            .mini_cart_line_title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                .title {
                    flex-grow: 1;
                }
                .lot_id {
                    flex-grow: 1;
                }
            }
            .mini_cart_line_block {
                display: flex;
                // justify-content: space-between;
                flex-grow: 1;
                align-items: center;
                div {
                    margin-right: 8px;
                }
                .flag-icon-2x {
                    font-size: 1em;
                }
                .mini_cart_line_price {
                    text-align: right;
                    // min-width: 140px;
                    // font-size: 11px;
                    display: flex;
                    // flex-grow:1;
                    justify-content: end;
                    .mini_cart_line_info {
                        //input number
                        justify-content: flex-end;
                    }
                }
                .mini_cart_line_info {
                    align-content: flex-start;
                    font-size: 11px;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    &--vertical_center {
                        display: flex;
                        align-items: center;
                    }
                    &__line {
                        display: flex;
                        margin-right: 10px;
                        white-space: normal;

                        &--dimensions {
                            span {
                                margin-right: 10px;
                                i {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                    &:last-child {
                        .mini_cart_line_info__line {
                            justify-content: flex-end;
                        }
                    }
                    &:first-child {
                        .mini_cart_line_info__line {
                            justify-content: flex-start;
                        }
                    }
                }

                .properties {
                    width: 100%;
                    max-width: 215px;
                    padding-top: 0;
                    @media (min-width: 768px) {
                        max-width: 375px;
                    }
                }
            }
        }
    }
    .delete-button {
        > div {
            margin: 0 5px;
            align-items: center;
        }
        align-items: flex-start;
        display: flex;
        justify-content: space-around;
    }
    .img {
        align-items: center;
        display: flex;
        justify-content: center;

        img {
            max-height: 100px;
            // max-width: 65px;
        }
    }
    span,
    div {
        &::first-letter {
            text-transform: capitalize;
        }
        &.centimeters {
            text-transform: initial;
        }
    }
    &.checkout {
        font-size: 13px;
        .delete-button,
        .img,
        .mini_cart_line_info.mini_cart_line_info--vertical_center {
            align-items: start !important;
            justify-content: flex-end;
        }

        img {
            max-height: 80px;
            max-width: 80px;
        }
    }
    .product-image-container {
        .product-image {
            background-position: top center !important;
        }

        background-repeat: no-repeat;
    }
}
.cart_line {
    &__link_image {
        width: 100%;
        height: 100%;
    }
}

.form-group__voucher {
    margin-top: 16px;

    .coupon {
        font-weight: 400;
        font-size: 16px;
    }
    input.input-voucher {
        border: 1px solid black;
        border-radius: 0;
        padding-right: 0;
    }
    div.input-group-append {
        margin-left: 0;
    }
    @media (min-width: 320px) and (max-width: 378px) {
        div.input-group-append {
            width: 100%;
            button.btn.btn-outline-secondary {
                width: 100%;
            }
        }
    }
    @media (min-width: 1024px) and (max-width: 1050px) {
        div.input-group-append {
            width: 100%;
            button.btn.btn-outline-secondary {
                width: 100%;
            }
        }
    }
}
.form-group {
    &.select_date {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__voucher {
        .wrapper-input {
            position: relative;
            flex: 1 1 auto;
            .icons-status {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                padding: 8px;
                height: 100%;
                line-height: 0;
            }
            input {
                padding-right: 2.25em;
            }
        }
        &--success {
            .wrapper-input {
                color: $success;
                input {
                    color: $success;
                    font-weight: bold;
                    border-color: $success;
                    background-color: rgba($success, 0.1);
                }
            }
        }
        &--failure {
            .wrapper-input {
                color: $error;
                input {
                    color: $error;
                    border-color: $error;
                    background-color: rgba($error, 0.1);
                }
                .clean-voucher-btn {
                    background: transparent;
                    border: 0;
                    color: $error;
                    outline: 0;

                    &:focus {
                        outline: 0;
                    }

                    &:hover {
                        background-color: rgba($error, 0.1);
                    }
                }
            }
        }
    }
}

.coupon-applied-successfully {
    color: rgb(54, 165, 150);
    margin-top: 16px;
    font-weight: 500;
}

.coupon-applied-error {
    color: rgb(165, 54, 54);
    margin-top: 16px;
    font-weight: 500;
    text-align: left;
}
