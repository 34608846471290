.bg-color-iron {
    background-color: $iron;
}

.bg-color-thunder {
    background-color: $thunder;
}

.bg-color-header {
    background-color: $headerBackground !important;
}

.bg-color-primary {
    background-color: $primary !important;
}