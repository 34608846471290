#home {
    .home-games-selector {
        display: flex;
        // margin:16px;
        grid-gap: 16px;
        max-width: 100%;
        cursor: pointer;
        @media (max-width: $mdMaxWidth) {
            flex-direction: column;
        }
        .home-games-selector__game {
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            // margin-top:24px;
            justify-content: space-between;
            flex:1;
        }
        .home-games-selector__logo {
            margin-top: 24px;
            margin-bottom: 24px;
        }
        .home-games-selector__cards {
            max-width: 100%;
        }
    }
    .home-games-cta {
        margin-top: 32px;
        width: 100%;
        display: flex;
        flex-direction: row;
        color: white;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        cursor: pointer;

        h1 {
            font-family: 'unbounded';
        }
        .home-games-cta__game {
            padding: 24px;
            border-radius: 16px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            align-items: center;
                    @media (max-width: $mdMaxWidth) {
            flex-direction: column;
        }
        }
        .home-games-cta__body {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
              @media (max-width: $mdMaxWidth) {
            align-items: center;
        }
        }
        .home-games-cta__cards {
            width: 360px;
        }
    }
}
