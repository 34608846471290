.checkout {
    .cart-line {
        display: flex;
        flex-direction: column !important;
        align-items: center;

        @media (min-width: $smMaxWidth) {
            flex-direction: row !important;
        }
    }
}