/* Contenedor Principal */
.simplified-result {
    width: 100%;
    max-height: 240px;
    margin: 0 auto;
    border: 1px solid #ddd;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    justify-content:flex-start !important;
    /* Estructura de la tarjeta */
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .card-image {
        // flex: 1 1 50%;
        height: auto;
        width: 180px;
        min-width: 180px;;
        height: 240px;
        display: flex;
        align-items: stretch; /* Asegura que el hijo ocupe todo el alto */
    }

    .image {
        background-repeat: no-repeat;
        background-size: cover; /* La clave para ocupar todo el alto */
        background-position: center;
    }

    /* Cuerpo del contenido */
    .card-body {
        flex: 1 1 50%;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-title {
        font-size: 1.2rem;
    }

    .card-expansion {
        display: flex;
        align-items: center;
        font-size: 0.7rem;
        color: #555;
    }

    .card-expansion img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    .card-price ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .card-price label {
        color: rgb(113, 113, 122);
    }

    .items-available {
        color: rgb(98, 189, 90);
        margin-top: 10px;
        font-size: 0.9rem;
    }
}
