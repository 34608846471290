#product-card {
    @media (min-width: $lgMaxWidth) {
        padding-right: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        .right-side-forms {
            box-shadow: -10px 0px 10px 1px #edf4f7;
        }
        .btn-group {
            background: linear-gradient(90deg, #edf4f7 1%, #dcdcdc 10%);
        }
    }
}
.btn-group {
    background: $bodyBackground;
    // background:linear-gradient(90deg, #edf4f7 1%, #dcdcdc 10%);
    padding: 0 !important;

    .btn {
        border-radius: 16px 16px 0 0 !important;

        background: #ececec;
        color: $headerBackground;
        border: none;
        margin-right: 2px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $lgMaxWidth) {
            font-size: 14px;
        }
        &:hover {
            background: white !important;
            color: black;
        }
        &.active {
            background: white;
            border-bottom: 2px solid black;
            color: $headerBackground;
        }
    }
}
.table-row {
    margin-bottom: 16px !important;
    font-size: 14px;
    .card-body {
        max-width: 70vw;
        padding:0px 16px;
    }
    .card-footer {
        justify-content: flex-start;
        align-items: center;
        .actions {
            flex-direction: row;
            width: 100%;
            justify-content: flex-end;
            .input-number {
                margin-right: 16px;
            }
        }
        .input-number {
            background: white;
            border-radius: 8px;
            .spin,
            input {
                background: white;
                border-radius: 8px;
            }
        }
    }
    .card-header,
    .card-footer {
        padding: 0;
    }
    .card-header div,
    .card-footer > div {
        .badge.badge-professional {
            border-radius: 10px;
            padding: 4px 8px;
            max-width: fit-content;
            display: inline;
            text-align: center;
            margin-left: 4px;
        }
        @media (max-width: $smMaxWidth) {
                        padding: 8px 12px;
       }
        padding: 16px;
        &:not(:first-child) {
            border-left: 1px solid lightgray;
        }
    }
    // .card-header div,.card-footer>div{
    // border-left: 1px solid lightgray;
    .card-body .properties div {
        margin-right: 16px;
    }
    .card-body .properties div,
    .card-header div,
    .card-footer > div {
        display: flex;

        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        > span {
            text-transform: uppercase;
        }
    }
    .card-footer > div > span {
        &:not(:first-child) {
            text-transform: uppercase;
            color: initial;
        }
    }
}
.right-side-forms {
    .price {
        input {
            border-radius: 4px;
            padding: 8px 8px;
        }
    }
    input[type='checkbox'] {
        margin-right: 8px;
    }
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: transparent transparent black !important;
}
.sales {
    .sales-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: $blackBackground;
        color: white;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
    }

    .sales-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        text-align: center;
    }
}

.card {
    border-radius: 16px !important; // replaces bootstrap styles

    .image {
        border-radius: 16px 16px 0 0;
    }
    //     max-width:140px;
    //     min-width:140px;
    //     @media (min-width:$lgMaxWidth){
    //         min-width:300px;
    //         max-width:300px;
    //     }
    // }
}
.collection form,
.sale form,
.buy form {
    .card {
        .image {
            max-width: 40px;
            min-width: 40px;
            @media (min-width: $lgMaxWidth) {
                min-width: 40px;
                max-width: 40px;
            }
        }
    }
}

#product-card {
    .card-image, .product-sealed-image {
        max-width: 100%;
        border-radius: 16px;
        @media (max-width: $lgMaxWidth) {
            border-radius: 8px;
        }
        margin-bottom: 16px;
    }
    .product-sealed-image {
        max-height: 400px;
    }
}
.properties {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 16px 0;
    margin: 0 16px;
    flex-grow:1;
    .badge {
        font-weight: 300;
        font-size: 14px;
    }
    > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 16px;
        > span {
            // text-transform: uppercase;
        }
    }
    .status {
        // min-width: 160px;
    }
}

.card-list {
    flex-flow: column !important;
    padding:0 !important;
    .card {
        border: 1px solid lightgray;


        @media (min-width: $mdMaxWidth) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            // max-height: 70px;
        }
        .card-header {
            border: none;
            border-right: 1px solid lightgray;
            border-radius: 16px 0px 0px 16px;
        
            @media (min-width: $lgMaxWidth) {
            min-width: 380px;
            max-width: 400px;
            }
            @media (max-width: $mdMaxWidth) {
                min-width: auto;
                border-radius: 16px 16px 0px 0px;
                border-bottom: 1px solid lightgray;
                border-right: none;
            }
            .img{
                width:60px;
                align-items: center;
                justify-content: center;
            }
        }
        .card-footer {
            display: flex;
            flex-direction: row;
            justify-content: start;
            border-radius: 0px 16px 16px 0px;
            width: auto;
                        @media (min-width: $lgMaxWidth) {

            max-width: 460px;
            min-width:320px;
                        }
            border: none;
            border-left: 1px solid lightgray;
            @media (max-width: $mdMaxWidth) {
                min-width: auto;
                border-radius: 0px 0px 16px 16px;
                border-left: none;
                border-top: 1px solid lightgray;
                flex-wrap: wrap;
            }
            .actions {
                // border-top:1px solid lightgray;
                @media (max-width: $mdMaxWidth) {
                    border-left: none;
                    border-top: 1px solid lightgray;
                }
                > div {
                    justify-content: space-between !important;
                    width: 100%;
                }
                .bt {
                    &:not(:last-child) {
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}
