.bt,
.sk-no-hits__step-action {
    background: black;
    // border: 1px solid $onyx;
    border-radius: 6px;
    color:$primary;
    border:none;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;

    cursor: pointer;
    font-size: 16px !important;
    font-weight: 300 !important;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none !important;

    .icon {
        display: none;
        height: 20px;
        margin-right: 5px;
        &.visible {
            display: inline-block;
        }
        [dir='rtl'] & {
            margin-right: unset;
            margin-left: 5px;
        }
    }

    .custom-icon {
        height: 20px;
        margin-left: 5px;
        [dir='rtl'] & {
            margin-right: unset;
            margin-left: 5px;
        }
    }

    &.bt-disabled {
        cursor: not-allowed;
        opacity: 0.35;
        background: #9F9F9F;
        color:black;
    }

    &:not(.bt-disabled) {
        &:hover {
            background: $thunder;
            border-color: $thunder;
            color: $white;
        }

        &:active,
        &.active {
            background: $onyx;
            border-color: $onyx;
            color: $white;
        }
    }

    &.bt-inline {
        display: inline-block;
    }

    &.bt-noborder {
        border: 0;
    }
    &.bt-secondary,
    &.sk-no-hits__step-action {
        background: white;
        color: $onyx;
        border:1px solid $onyx;
        font-weight: 600;

        &:not(.bt-disabled) {
            &:hover {
                background: $thunder;
                border-color: $thunder;
            }

            &:active,
            &.active {
                background: $iron;
                border-color: $iron;
                color: $thunder;
            }
        }
    }
    &.bt-inverted,
    &.sk-no-hits__step-action {
        background: $primary;
        color: $onyx;
        font-weight: 600;

        &:not(.bt-disabled) {
            &:hover {
                background: $thunder;
                border-color: $thunder;
            }

            &:active,
            &.active {
                background: $iron;
                border-color: $iron;
                color: $thunder;
            }
        }
    }

    &.bt-gentle {
        background: $thunder;
        border-color: $thunder;
        color: $white;

        &:not(.bt-disabled) {
            &:hover {
                background: $iron;
                border-color: $iron;
                color: $onyx;
            }

            &:active,
            &.active {
                background: $smoke;
                color: $thunder;
            }
        }
    }
    
    &.bt-danger {
        border-color: #DF0000;
        color: #DF0000;
        
        &:not(.bt-disabled) {
            &:hover {
                background: #DF0000;
                border-color: #DF0000;
                color: white;
            }

            &:active,
            &.active {
                border-color: #B10000;
                background: darken(#B10000, .7);
                color: white;
            }
        }
    }

    &.bt-size-large {
        font-size: 18px !important;
        padding: 4px 15px;

        .icon,
        .custom-icon {
            height: 15px;
        }
    }

    &.bt-size-medium {
        font-size: 14px !important;
        padding: 4px 15px;

        .icon,
        .custom-icon {
            height: 15px;
        }
    }

    &.bt-size-small {
        font-size: 12px !important;
        padding: 2px 10px;

        .icon,
        .custom-icon {
            height: 12px;
        }
    }

    &.bt-size-tiny {
        font-size: 10px !important;
        padding: 1px 8px;

        .icon,
        .custom-icon {
            height: 10px;
        }
    }

    &.bt-hidden {
        display: none !important;
    }
    &.only-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            margin: 0;
        }
    }
    &--thin {
        height: 26px;
    }
}
.sk-no-hits__step-action {
    width: fit-content;
    margin: 0 auto;
    margin-top: 10px;
}
.input-group-append {
    .btn {
        height: 100%;
    }
}
