// advancedd Search

$input-font-size: 12px;
$input-height: 20px;

.advanced-search-card {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  width: 15em;
  height: 28em;
}
// Card Name
.advanced-search-card-name-input__text {
  position: relative;
  top: 12px;
  left: 14px;
  width: 99px;
  font-size: $input-font-size;
  height: $input-height;
  border-radius: 7px 0 0 7px;
  border-right: hidden;
}

.advanced-search-card-name-input__action {
  display: none;
}

// Mana Cost

.advanced-search-mana-cost-input__text {
  position: relative;
  bottom: 12px;
  left: 113px;
  width: 67px;
  font-size: $input-font-size;
  height: $input-height;
  border-left: hidden;
  border-right: hidden;
}

.advanced-search-mana-cost-input__action {
  display: none;
}

// CMC

.advanced-search-cmc-input__text {
  position: relative;
  bottom: 36px;
  left: 180px;
  width: 45px;
  font-size: $input-font-size;
  height: $input-height;
  border-left: hidden;
  border-radius: 0 7px 7px 0;
}

.advanced-search-cmc-input__action {
  display: none;
}

// Type

.advanced-search-type-input__text {
  position: relative;
  top: 113px;
  left: 14px;
  width: 111px;
  font-size: $input-font-size;
  height: $input-height;
  border-radius: 7px 0 0 7px;
  border-right: hidden;
}

.advanced-search-type-input__action {
  display: none;
}

// Set

.advanced-search-set-input__text {
  position: relative;
  top: 89px;
  left: 125px;
  width: 100px;
  font-size: $input-font-size;
  height: $input-height;
  border-radius: 0 7px 7px 0;
  border-left: hidden;
}

.advanced-search-set-input__action {
  display: none;
}

// Text

.advanced-search-text-input__text {
  position: relative;
  top: 90px;
  left: 16px;
  width: 205px;
  font-size: $input-font-size;
  height: 45px;
}

.advanced-search-text-input__action {
  display: none;
}

// Flavor

.advanced-search-flavor-input__text {
  position: relative;
  top: 83px;
  left: 16px;
  width: 205px;
  font-size: $input-font-size;
  height: 45px;
}

.advanced-search-flavor-input__action {
  display: none;
}

// Power

.advanced-search-power-input__text {
  position: relative;
  top: 84px;
  left: 180px;
  width: 25px;
  font-size: $input-font-size;
  height: $input-height;
  border-radius: 7px 0 0 7px;
  border-right: hidden;
}

.advanced-search-power-input__action {
  display: none;
}

// Toughness

.advanced-search-toughness-input__text {
  position: relative;
  top: 60px;
  left: 205px;
  width: 25px;
  font-size: $input-font-size;
  height: $input-height;
  border-radius: 0 7px 7px 0;
  border-left: hidden;
}

.advanced-search-toughness-input__action {
  display: none;
}

// Artist
.advanced-search-artist-input__text {
  position: relative;
  top: 50px;
  left: 16px;
  width: 90px;
  font-size: $input-font-size;
  height: $input-height;
  border-radius: 7px;
}

.advanced-search-artist-input__action {
  display: none;
}

// Rarity

.filter--advanced-search-rarity {
  position: relative;
  top: 55px;

  .sk-panel__content {
    font-size: 10px;
    border-color: lightgrey;
    border-width: 1px;
    border-style: solid;
    overflow: auto;
    border-radius: 7px;

    .sk-item-list-option {
      padding: 0 0 0 5px;
      text-transform: capitalize;
      cursor: pointer;
      &.is-active {
        background-color: #e6e6e6;
      }
    }


  }
}

// End advanced Search