#header {
    position: fixed;
    top: 0px;
    z-index: $layer20;
    width: calc(100vw - 144px);
    @media all and (max-width: 767px) {
        width: calc(100vw - 32px);
    }
    background: $bodyBackground;
    box-shadow: 1px 1px 1px 1px $bodyBackground;

    color: white;
    // margin: 16px; //preguntar a jota
    // margin: 16px 0 0 0;
    // border-radius:16px;
    padding: 16px 0 14px; //abajo tiene 2px menos porque el shadow añade 1px y se corta el contenido del container un poquito, con 14px + el shadow va perfecto

    #header-search-collapse__button {
        display: none;
        color: white;
        font-size: 32px;
        position: absolute;
        width: 120px;
        height: 60px;
        background: rgb(16, 24, 35);
        top: 26px;
        right: 16px;
        align-items: center;
        justify-content: flex-end;
        border-radius: 16px;
        z-index: 100;
    }

    .sk-search-box {
        background: white;
        border-radius: 25px;
        border: 0cm;
        font-size: 21px;
        width: fit-content;
        @media all and (max-width: 767px) {
            position: absolute;
            top: 26px;
            left: 10px;
            z-index: $layer20;
        }
        &.is-focused {
            .sk-search-box__text {
                @media all and (max-width: 767px) {
                    width: 248px;
                }
                @media all and (max-width: 341px) {
                    width: 216px;
                }
            }
            +#header-search-collapse__button {
                @media all and (max-width: 767px) {
                    display: flex;
                }
            }
        }
        .sk-search-box__icon {
            position: relative;
            width: 100%;
            font-size: 21px;
            &:before {
                color: $onyx;
                content: '\f002';
                font-family: 'Font Awesome 5 Pro';
                left: 16px;
                position: absolute;
                top: 16px;
                width: 0;
                height: 0;
                [dir='rtl'] & {
                    left: unset;
                    right: 12px;
                }
            }
        }

        .sk-search-box__text {
            background: white;
            color: $onyx;
            font-size: 21px;
            border: none;
            border-radius: 12px;
            padding: 7px 15px 5px 40px;
            outline: none;
            height:60px;
            [dir='rtl'] & {
                padding: 7px 40px 5px 15px;
            }
            width: 54px;


            @media all and (min-width: 767px) {
                width: 240px;
            }
            @media all and (min-width: 1024px) {
                width: 360px;
            }
            @media all and (min-width: 1440px) {
                width: 480px;
            }
            // ENTRA EN CONFLICTO CON LOS ESTILOS DE &.is-focused
            //@media all and (max-width: 767px) {
            //    width: 80px;
            //    &:focus {
            //        width: 280px;
            //    }
            //}
            //@media all and (max-width: 419px) {
            //    width: 54px;
            //    &:focus {
            //        width: 248px;
            //    }
            //}
        }
    }

    .bt-reset-searchbox {
        position: absolute;
        top: 8px;
        right: 10px;
        width: 21px;
        height: 21px;

        background: $onyx;
        border-radius: 10px;

        cursor: pointer;

        &:before {
            position: absolute;
            top: 1px;
            width: 100%;

            font-family: 'Font Awesome 5 Pro';
            font-size: 14px;
            content: '\f00d';
            text-align: center;
            color: $white;
        }
        [dir='rtl'] & {
            right: unset;
            left: 10px;
        }
    }
}
// box-shadow: 0px 2px 10px #888888;

#main-header {
    // border-bottom: 1px solid $mercury;
    height: 80px;
    background: $headerBackground;
    border-radius: 16px;

    > .container {
        height: 80px;
    }

    @media (max-width: $smMaxWidth) {
        top: 60px;
    }

    #logo {
        // margin: 20px 0;

        img {
            width: 165px;
        }
    }

    nav#main-menu {
        // margin: 20px 0;
        text-align: center;

        ul {
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin: 0 10px;

                a,
                span {
                    color: $iron;
                    font-family: $titleMenuFont;
                    font-size: 17px;

                    &:hover,
                    &.active {
                        color: $onyx;
                        border-bottom: 1px solid $onyx;
                    }
                }
            }
        }

        .has-submenu {
            position: relative;

            &:hover .submenu {
                opacity: 1;
                visibility: visible;
                pointer-events: unset;
            }
        }

        .submenu {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;

            position: absolute;
            top: calc(100% + 1rem);
            left: 0;
            width: 30vw;
            background: #fff;
            padding: 0.8rem;
            border-radius: 2px;
            box-shadow: 0 3px 6px rgba($onyx, 0.32);
            transition: all 0.3s ease-out 0s;

            &::before {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 0;
                right: 0;
                height: 1rem;
                background: transparent;
            }

            li {
                display: block;
                text-align: left;

                &:not(:last-child) {
                    border-bottom: 1px solid rgba($onyx, 0.32);
                    padding-bottom: 0.2rem;
                    margin-bottom: 0.2rem;
                }

                a {
                    // white-space: nowrap;

                    &.nav-link {
                        padding: 0;
                    }
                }
            }
        }
    }

    nav#top-side-menu {
        // margin: 20px 0;
        min-width: 200px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: right;

            & > li {
                display: inline-block;
                cursor: pointer;

                & > a,
                & > span {
                    color: #C2C1C3;
                    padding: 10px 15px;
                    @media (max-width: $lgMaxWidth) {
                        padding: 10px;
                    }
                    // border-left: 1px solid #ddd;

                    &.cart {
                        position: relative;
                        display: inline-block;

                        .badge {
                            // background: rgba($onyx, 0.85);

                            background: #ff5555;
                            color: white;
                            position: absolute;
                            top: 0;
                            right: -8px;
                            // border-radius: 100%;
                        }
                    }

                    &:hover {
                        color: white;
                    }

                    .icon {
                        font-size: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }

    #mobile-main-menu-toggler {
        background: none;
        border: none;
        padding: 20px 0;
        font-size: 24px;
        color: $thunder;
        cursor: pointer;

        &:hover {
            color: $onyx;
        }
    }
}

#mobile-main-menu {
    // background: $smoke;
        background: #101823;
    color: #C2C1C3;
    padding: 0;
    width: 100%;
    z-index: $layer20;
    // bottom:120px;
    position: absolute;
    .navbar-nav {
        padding: 10px 20px;
    
        // .dropdown .nav-link{
        //     flex-direction: row !important;
        // }
    }

    .submenu {
        list-style: none;
        padding-left: 1rem;
        li {
            margin: 0 0 0.2rem;
        }
        a {
            padding: 0;
        }
    }
}

#main-submenu {
    background: $smoke;
    text-align: center;

    nav {
        ul {
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;

                a,
                span {
                    display: inline-block;
                    font-size: 14px;
                    color: #C2C1C3;
                    padding: 8px 10px;

                    &:hover {
                        color: $onyx;
                    }
                }
            }
        }
    }
}
.extra-discount-mall {
    border-radius: 4px;
    background: #aed8d3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    margin: 20px;
    img {
        width: 29px;
        margin-right: 20px;
    }
    .alert__title {
        font-size: 20px;
        font-weight: 500;
        color: #232323;
    }
}
.checkout {
    .extra-discount-mall {
        margin: 0px 0px 10px;
        padding: 10px 20px;
        min-height: 49px;
    }
}

#mini-cart-container, #mini-notifications-container {
    position: absolute;
    top: 80px;
    right: 0;
    width: 560px;
    text-align: left;
    background: $white;
    border-radius: 5px;
    z-index: $layer30;
    max-height: 80vh;
    overflow-y: auto;
    cursor: auto;

    @media (max-width: $mdMaxWidth) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        max-height: none;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
    }

    #mini-cart, #mini-notifications {
        .bt{
            padding:1em;
        }
        color: white;
        .card{
            color:$thunder;
        }

        


        header {
            padding: 20px 25px;

            .bt-close {
                display: inline-block;
                cursor: pointer;

                &:hover {
                    color: $onyx;
                }

                i {
                    font-size: 40px;
                }
            }
        }

        .item-list {
            padding: 0 16px;

            max-height: 50vh;

            overflow-y: auto;

            &.more-height {
                max-height: 300px;
            }

            .item {
                padding: 10px 0;
                border-bottom: 1px solid $mercury;

                &:first-child {
                    border-top: 1px solid $mercury;
                }

                .title {
                    font-weight: 600;
                    text-transform: capitalize;
                    font-weight: 600;
                }

                .subtitle {
                    font-size: 11px;
                    text-transform: capitalize;
                }

                .price {
                    margin: 2px 0;
                }

                .quantity-label {
                    display: block;
                    color: $gray;
                    font-size: 12px;
                }
                .bundle-content {
                    cursor: pointer;
                    color: deepskyblue;
                }

                .bt-edit {
                    cursor: pointer;
                }

                .bt-delete {
                    color: $gray;
                    font-size: 14px;
                    text-transform: uppercase;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .total {
            margin-top: 20px;
            // background: $blackBackground;
            // color: white;

            &.cart-empty {
                .label {
                    padding: 10px 0;
                }
            }

            .row {
                padding: 0 40px;

                .label {
                    // font-size: 15px;
                    margin: 3px 10px 3px 10px;
                }

                .quantity {
                    // font-size: 15px;
                    // background: $white;
                    // color: $blackBackground;
                    // margin: 10px 3px 10px 20px;
                    // padding: 3px 10px 3px 10px;
                    width: fit-content;
                }

                .amount {
                    color: $onyx;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }

        .cross-selling {
            color: $thunder;
            padding: 0 40px;
            font-size: 14px;

            .item {
                padding: 10px 0;
                border-bottom: 1px solid #ddd;

                .image {
                    max-height: 100px;
                }
            }
        }

        footer {
            padding: 20px 20px;
        }
    }
}
[dir='rtl'] {
    #mini-cart-container, #mini-notifications-container {
        right: unset;
        left: 0;
        text-align: right;
    }
}

#user-profile-modal-container{
    position: absolute;
    top: 80px;
    right: 0;
    width: 400px;
    text-align: left;
    background: #101823;
    color: #C2C1C3;

    border-radius: 5px;

    z-index: $layer30;
    border-radius: 0 0 16px 16px;
 @media (max-width: $mdMaxWidth) {
        top: 80px;
        right:16px;
        // left: -16px;
        // bottom: 0;
        width: calc(100vw - 32px);
        position:fixed;

     
    }
    //temporal
    // @media (max-width: $mdMaxWidth) {
    //     background: $aluminum;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     width: 100%;
    //     overflow-x: none;
    //     overflow-y: scroll;
    //     -webkit-overflow-scrolling: touch;
    // }
    ul > li > a,
    ul > li > span {
        padding: 0px !important;
    }
    .bt-close {
        display: inline-block;
        cursor: pointer;

        &:hover {
            color: white;
        }

        i {
            font-size: 40px;
        }
    }
    .user-profile-menu {
        .badge {
            background: rgba($onyx, 0.85);
        }
            .nav-link{
                                    color:#62BD5A !important;

        }
    }
}

.user-profile {
    header {
        text-align: center;
        padding: 10px;
        .icon {
            font-size: 25px;
            border: 1px solid black;
            border-radius: 100%;
            height: 40px;
            margin-right: 10px;
            padding: 5px;
        }
    }
    ul {
        text-align: center !important;
        li {
            padding: 10px;
            display: block;
        }
    }

    a,
    span {
        border: none !important;
    }
    footer {
        padding: 20px;
    }
}
.user-profile-responsive {
    > img {
        margin-right: 20px;
        padding: 10px;
        border: 1px solid black;
        border-radius: 100%;
    }
}

[dir='rtl'] {
    #main-header {
        nav#top-side-menu {
            display: flex;
            min-width: 200px;
            ul {
                text-align: left;
                & > li > a,
                & > li > span {
                    border-left: none;
                    border-right: 1px solid #ddd;
                }
            }
        }
    }

    #user-profile-modal-container {
        right: unset;
        left: 0;
    }
}
