/*
 * Specific styles of signin component
 */

/*
 * General styles
 */
.modal-ask-for-email {
    top: 10%;
    max-width: 568px;
    background: #d9dedf;

    .card-container {
        background: #d9dedf;
    }

    h3,
    label {
        color: #333;
    }

    #profile-img {
        top: 0;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    input[type='email'],
    input[type='password'],
    input[type='text'],
    button {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 20px;
    }
}

#modal-login-container {
    position: fixed;
    top: 20%;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 520px;

    z-index: $layer50;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $mdMaxWidth) {
        background: $headerBackground;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

.modal-login {
    label,
    .forgot-password a,
    .forgot-password span {
        color: $white;
    }

    header {
        padding: 20px 25px;

        .bt-close {
            display: inline-block;
            cursor: pointer;
            color:white;

            &:hover {
                color: $onyx;
            }

            i {
                font-size: 40px;
            }
        }
    }

    footer {
        padding: 20px;
    }

    #profile-img {
        position: relative;
    
    }

    .logo-container {
        background: $headerBackground;
        display:flex !important;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        [dir='rtl'] & {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .card-container {
        background: $headerBackground;
            padding-bottom:16px;


        @media (max-width: $mdMaxWidth) {
            border-radius: 0;
        }

        [dir='rtl'] & {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }

    .form-signin {
        width: 80%;
        margin: 0 auto;
                @media (max-width: $mdMaxWidth) {
            margin-top: 32px;
        }
    }

    .links{
        a,
        span {
            cursor: pointer;
            color: white !important;
        }
    }
    .register-footer{
                a,
        span {
            cursor: pointer;
            color: $primary !important;
            text-transform: none;
            text-decoration:none;
        }
    }
}

.container-login {

    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.container-layer {
    background: rgba(0, 0, 0, 0.5);
    padding-top: 60px;
    padding-bottom: 60px;
    width: 100%;
    height: 100%;
}

.btn {
    font-weight: 200;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

/*
 * Card component
 */
// .card {
//     max-width: 530px;
//     width: 80%;
//     background-color: white;
//     border: none;
//     box-shadow: none;
//     /* just in case there no content*/
//     padding: 20px 80px 30px;
//     margin: 0 auto 25px;
//     margin-top: 50px;
//     /* shadows and rounded borders */
//     -moz-border-radius: 2px;
//     -webkit-border-radius: 2px;
//     -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
//     border-radius: 0.35rem;

//     @media (max-width: $xsMaxWidth) {
//         padding: 20px 20px 30px;
//     }
// }

.profile-img-card {
    width: 240px;
    margin: 0 auto 20px;
    padding-top: 30px;
    display: block;
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.remember-me {
    text-align: left;
    color: $thunder;
}

.forgot-password {
    color: $thunder;
    text-align: right;

    a,
    span {
        color: $thunder;
    }

    .forgot-password:hover,
    .forgot-password:active,
    .forgot-password:focus {
        color: rgb(12, 97, 33);
    }
}

.form-signin {

    #inputEmail,
    #inputPassword {
        direction: ltr;
        height: 44px;
        font-size: 16px;
    }

    input[type='email'],
    input[type='password'],
    input[type='text'],
    button {
        width: 100%;
        display: block;
        margin-bottom: 10px;
        position: relative;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: $headingBorderRadius;
    }

    .form-control:focus {
        border-color: rgb(60, 60, 60);
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(60, 60, 60);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(60, 60, 60);
    }

    .loginbutton {
        width: 100%;
        margin-bottom: 10px;
    }

    .btn {
        background-color: black;
        padding: 0px;
        font-weight: 200;
        font-size: 14px;
        height: 36px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border: none;
        -o-transition: all 0.218s;
        -moz-transition: all 0.218s;
        -webkit-transition: all 0.218s;
        transition: all 0.218s;
        border-radius: $headingBorderRadius;

        .btn.btn-signin:hover,
        .btn.btn-signin:active,
        .btn.btn-signin:focus {
            background-color: rgb(12, 97, 33);
        }
    }
}

.sso .bt {
    padding: 10px;
    margin-top: 20px;
}
.register-footer {
    display: flex;
    align-items: center;
    padding:32px;
    height:48px;
    
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), $headerBackground;

    font-weight: 200;
    color:white;
        a,
        span {
            cursor: pointer;
            color: $primary !important;
        }
    }