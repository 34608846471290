.registerForm {
    padding-top: 40px;

    .registerImg {
        max-width: 80px;
    }

    @media (min-width: $mdMaxWidth) {
        .rounded-box {
            border-radius: 0px 0px 0px 70px;
        }   

    }
        @media (max-width: $smMaxWidth) {
        .rounded-box {
            padding:0 16px;
        }   

    }

    .rounded-box {
        background:white;
        border: 2px solid #c6c6c6;
        position: relative;
        width: 99%;
        min-height: 460px;
    }
    

    .q-top {
        border-bottom: 2px solid $table-border;
        color: $thunder;
        cursor: default;
        // font-family: $titleMenuFont;
        font-size: 24px;
        height: 65px;
        // letter-spacing: 3px;
        line-height: 60px;
        overflow: hidden;
        // margin: 0 24px;
        position: relative;
    }

    .q-left {
        margin: 20px 0 10px;
    }

    h2 {
        // font-family: $titleMenuFont;
        color: $thunder;
        font-weight: 700;
        font-size: 40px;
    }

    .register_button {
        width: 100%;
        background-color: $thunder;
        color: $solitude;
        border-color: $solitude;

        &:hover {
            color: #fff;
            background-color: $iron;
            border-color: $iron;
        }
    }
    a {
        color: $gray;

        &:hover {
            color: $thunder;
        }
    }
}
